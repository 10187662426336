import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import * as requestParamTypes from '../../../../consts/app/requestParamTypes';
import * as formInputNames from '../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import {reportTypes} from '../../../../consts/reports/reportTypes.ts';
import {MANAGER} from '../../../../consts/user/userRolesId.js';
import buildingsService from '../../../../services/buildings/buildingsService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import organizationsService from '../../../../services/organizations/organizationsService';
import reportsService from '../../../../services/reports/reportsService.js';
import currentUserService from '../../../../services/user/currentUserService.js';
import {makeSelectBuildingsData, makeSelectDeviceTypeGroups} from '../../../../state/selectors/buildings';
import {
    makeSelectCurrentUserInfo,
    makeSelectCurrentUserResponsibilitiesData,
} from '../../../../state/selectors/currentUser.js';
import {makeSelectDevices} from '../../../../state/selectors/device';
import {makeSelectCities, makeSelectOrganizationsNames} from '../../../../state/selectors/organizations';
import {makeSelectReportTypes} from '../../../../state/selectors/reports';
import helpers from '../../../../utils/helpers';

const getModalContent = () => {
    const localizedStrings = getLocalizedStrings();
    const currentUserInfo = useSelector(makeSelectCurrentUserInfo());
    const currentUserResponsibilitiesData = useSelector(makeSelectCurrentUserResponsibilitiesData()) || {};
    const userResponsibilities = currentUserResponsibilitiesData?.items || [];
    const firstCurrentResponsibility = userResponsibilities[0] || {};
    const {companyID, cityID} = firstCurrentResponsibility || {};
    const isOneResponsibility = userResponsibilities.length === 1;
    const {roleId, id: userID} = currentUserInfo || {};

    const isManager = roleId === MANAGER;
    const title = localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_TITLE];
    const cancelButton = localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_CANCEL_BUTTON];
    const submitButton = localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_SUBMIT_BUTTON];

    const requireDeps = formInputNames.REPORT_TYPE;

    const isCurrentReport = (id) => id === reportTypes.Current;
    const isIndividualReport = (id) => id === reportTypes.Individual;
    const isBuildingReport = (id) => id === reportTypes.Building;

    let initialValues = {};

    if (isOneResponsibility) {
        initialValues = {
            [formInputNames.CITY]: firstCurrentResponsibility?.city && {
                id: firstCurrentResponsibility?.cityID,
                value: firstCurrentResponsibility?.city,
            },
            [formInputNames.COMPANY]: firstCurrentResponsibility?.companyName && {
                id: firstCurrentResponsibility?.companyID,
                value: firstCurrentResponsibility?.companyName,
            },
        };
    }

    if (isManager) {
        useEffect(() => {
            currentUserService.getCurrentUserResponsibilitiesListByIdWithData({userID});
        }, []);
    }

    const formContent = [
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            required: true,
            name: formInputNames.REPORT_TYPE,
            label: localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_TYPE_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_TYPE_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_TYPE_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectReportTypes,
                getRequestData: () => reportsService.getReportTypes(),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            required: (id) => !!id,
            name: formInputNames.CITY,
            disabled: (_, reportType) => !reportType,
            itemDeps: formInputNames.REPORT_TYPE,
            label: localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_CITY_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_CITY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_CITY_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectCities,
                getRequestData: () => organizationsService.getCities(),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            itemDeps: formInputNames.CITY,
            required: (id) => !!id,
            disabled: (_, cityId) => !cityId,
            name: formInputNames.COMPANY,
            label: localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COMPANY_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COMPANY_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COMPANY_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectOrganizationsNames,
                getRequestData: () => organizationsService.getOrganizationsNames(cityID),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            fieldValue: 'value',
            itemDeps: formInputNames.COMPANY,
            required: (id) => !!id,
            disabled: (_, companyId) => !companyId,
            name: formInputNames.BUILDING,
            withSearch: true,
            label: localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_BUILDING_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_BUILDING_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_BUILDING_FIELD_ERROR],
            requestOptions: {
                paramsType: requestParamTypes.BUILDINGS,
                selectRequestData: makeSelectBuildingsData,
                getRequestData: () => buildingsService.getBuildingsListByOrganizationId(companyID),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            name: formInputNames.DEVICE_TYPE,
            itemDeps: formInputNames.BUILDING,
            required: (id) => !!id,
            disabled: (report, buildingId) => !report?.id || !buildingId,
            fieldLabel: 'value',
            fieldValue: 'key',
            label: localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_KIND_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_KIND_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_KIND_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectDeviceTypeGroups,
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            name: formInputNames.DEVICE_ID,
            itemDeps: formInputNames.DEVICE_TYPE,
            // required: (report) => isIndividualReport(report?.id),
            // disabled: (report, deviceType) => (!isIndividualReport(report?.id) || !deviceType ? true : !deviceType),
            required: (report) => isIndividualReport(report?.id) || isBuildingReport(report?.id),
            disabled: (report, deviceType) => {
                if ((!isIndividualReport(report?.id) && !isBuildingReport(report?.id)) || !deviceType) {
                    return true;
                }
                return false;
            },
            fieldLabel: 'value',
            label: localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COUNTER_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COUNTER_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COUNTER_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectDevices,
            },
        },
        {
            key: helpers.guid(),
            name: formInputNames.START_DATE,
            required: (report) => !!report && !isCurrentReport(report?.id),
            disabled: (report) => isCurrentReport(report?.id) || !report,
            formItemType: formItemTypes.DATE_PICKER,
            label: localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_START_DATE_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_START_DATE_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_START_DATE_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            name: formInputNames.END_DATE,
            itemDeps: formInputNames.START_DATE,
            required: (report) => !!report && !isCurrentReport(report?.id),
            disabled: (id, currentStartDate) => !id || !currentStartDate,
            formItemType: formItemTypes.DATE_PICKER,
            label: localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_END_DATE_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_END_DATE_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_END_DATE_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.REPORT_FORMAT,
            formItemType: formItemTypes.RADIO,
            label: localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_FILE_TYPE_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_FILE_TYPE_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_FILE_TYPE_FIELD_ERROR],
            radioButtonOptions: [
                {
                    value: 1,
                    name: 'PDF',
                    selected: true,
                },
                {
                    value: 2,
                    name: 'Excel',
                },
            ],
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        formContent,
        requireDeps,
        initialValues,
    };
};

export default {getModalContent};
